import { Component } from '@angular/core';

import { SwiperComponent } from "swiper/angular";

import SwiperCore from 'swiper';


// import { Animation, AnimationController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(){

    // const animation: Animation = this.animationCtrl.create()
    //   .addElement(myElementRef)
    //   .duration(1000)
    //   .fromTo('opacity', '1', '0.5');

  }

}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';

import Amplify, { Auth, Storage } from 'aws-amplify';

import { SwiperModule } from 'swiper/angular';

Amplify.configure({
    Auth: {
        identityPoolId: 'us-east-1:6b51251f-b84c-46ea-a20a-6e826d7c8c63', //REQUIRED - Amazon Cognito Identity Pool ID
        region: 'us-east-1', // REQUIRED - Amazon Cognito Region
        userPoolId: 'us-east-1_tYqDQFdOy', //OPTIONAL - Amazon Cognito User Pool ID
        // userPoolWebClientId: 'XX-XXXX-X_abcd1234', //this is the template, so i think its wrong high key
        userPoolWebClientId: '2qu7ok9g4farrpmpsd07v3uqiq', //OPTIONAL - Amazon Cognito Web Client ID

    },
    Storage: {
        AWSS3: {
            bucket: 'irlfgbucket', //REQUIRED -  Amazon S3 bucket name
            region: 'us-east-1', //OPTIONAL -  Amazon service region
        }
    }
});



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, SwiperModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}

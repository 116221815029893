import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then( m => m.LandingPageModule)
  },
  { path: '', redirectTo: '/overview', pathMatch: 'full' },
  {
    path: 'header',
    loadChildren: () => import('./header/header.module').then( m => m.HeaderPageModule)
  },
  {
    path: 'createpost',
    loadChildren: () => import('./createpost/createpost.module').then( m => m.CreatepostPageModule)
  },
  {
    path: 'editprofile',
    loadChildren: () => import('./editprofile/editprofile.module').then( m => m.EditprofilePageModule)
  },
  {
    path: 'landing2',
    loadChildren: () => import('./landing2/landing2.module').then( m => m.Landing2PageModule)
  },
  {
    path: 'tutorial1',
    loadChildren: () => import('./tutorial1/tutorial1.module').then( m => m.Tutorial1PageModule)
  },
  {
    path: 'tutorial2',
    loadChildren: () => import('./tutorial2/tutorial2.module').then( m => m.Tutorial2PageModule)
  },
  {
    path: 'tutorial3',
    loadChildren: () => import('./tutorial3/tutorial3.module').then( m => m.Tutorial3PageModule)
  },
  {
    path: 'details/:postid',
    loadChildren: () => import('./details/details.module').then( m => m.DetailsPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'overview',
    loadChildren: () => import('./overview/overview.module').then( m => m.OverviewPageModule)
  },
  {
    path: 'viewprof/:profid',
    loadChildren: () => import('./viewprof/viewprof.module').then( m => m.ViewprofPageModule)
  }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
